
import axios from 'axios';
import { api } from '../../api'


export const GET_DATA = "GET_DATA"
export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS'
export const GET_DATA_FAILURE = 'GET_DATA_FAILURE'

export const get_data = () => ({ type: GET_DATA })
export const get_data_success = (data) => ({ type: GET_DATA_SUCCESS, payload: data, })
export const get_data_failure = () => ({ type: GET_DATA_FAILURE })



export function all_data() {
    return async dispatch => {
        try {
            let user = JSON.parse(localStorage.getItem('anoop_cred'))
            if(!user.username){
                localStorage.removeItem('anoop_cred')
            }
            const res = await axios.get(`${api}/orders.php?all=${user.username}`)
            const data = await res.data.data
            dispatch(get_data_success(data))
        }
        catch (error) {
            dispatch(get_data_failure())
        }
    }
}






