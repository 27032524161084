import { Routes, Route } from "react-router-dom";
import Main from "./components/layout/Main.jsx";
import Home from "./pages/Home.jsx";
import Orders from "./pages/order.jsx";
import SignIn from "./pages/SignIn.jsx";
import ViewOrder from "./pages/viewOrder.jsx";
import OrderTakeDown from "./pages/orderTakeDown.jsx";
import { ProtectedRoute, AdminProtection } from "./hook/useAuth.jsx";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import User from "./pages/Users.jsx";
import Adduser from './pages/SignUp.jsx'

function App() {
  return (
    <div className="App">
      <Routes>
        {<Route path="/sign-in" exact element={<SignIn />} />}
        <Route
          exact
          path="/"
          element={
            <ProtectedRoute>
              <Main />
            </ProtectedRoute>
          }
        >
          <Route exact path="/home">
            <Route
              path=""
              element={
                <AdminProtection>
                  <Home />
                </AdminProtection>
              }
            />
          </Route>
          <Route exact path="/orders">
            <Route path="" element={<Orders />} />
            <Route path=":id" element={<ViewOrder />} />
          </Route>
          
          <Route exact path="/addUser" element={<Adduser />} />
          <Route exact path="/users" element={<User />} />
          <Route exact path="/orderTakeDown" element={<OrderTakeDown />} />
          

        </Route>
      </Routes>
    </div>
  );
}

export default App;
