import ReactApexChart from "react-apexcharts";
import { useEffect } from "react";




function EChart({data}) {
  useEffect(() => {
  }, [data])



  return (
    <>
        <div id="chart">
          <ReactApexChart
            className="bar-chart"
            series={[
              {
                name: "Sales",
                data: data.data && data.data.length > 0 ?  data.data : [],
                color: "#fff",
              }
            ]}

            options={{
              chart: {
                type: "bar",
                width: "100%",
                height: "auto",

                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: "55%",
                  borderRadius: 5,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                show: true,
                width: 1,
                colors: ["transparent"],
              },
              grid: {
                show: true,
                borderColor: "#ccc",
                strokeDashArray: 2,
              },
              xaxis: {
                categories: data.products && data.products.length > 0 ?  data.products : [],
                labels: {
                  formatter: value => {
                    return value.slice(0,12) + '..'
                  },
                  style: {
                    fontSize: 10,
                    colors: "#ffffff",
                  },
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                 
                },
              },
              yaxis: {
                labels: {
                  show: true,
                  align: "right",
                  minWidth: 0,
                  maxWidth: 160,
                  style: {
                    colors: [
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                      "#fff",
                    ],
                  },
                },
              },
              tooltip: {
                y: {
                  formatter: function (val) {
                    return "$" + val + " thousands";
                  },
                },
              },
            }}
            type="bar"
            height={350}
          />
        </div>
          </>
  );
}

export default EChart;
