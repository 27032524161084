import { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Menu,
  } from "antd";
  import axios from "axios";
  import { SearchOutlined } from '@ant-design/icons';
  import { Input, Space , Button} from 'antd';
  import Highlighter from 'react-highlight-words'
  import {api} from '../api/'
  import UseIsMounted from '../hook/mounted';



  function OrderTakeDown() {
    const [data , setData] = useState([])
    const isMounted = UseIsMounted();


         // table filter
         const [searchText, setSearchText] = useState('');
         const [searchedColumn, setSearchedColumn] = useState('');
         const searchInput = useRef(null);
         const handleSearch = (selectedKeys, confirm, dataIndex) => {
             confirm();
             setSearchText(selectedKeys[0]);
             setSearchedColumn(dataIndex);
         };
         const handleReset = (clearFilters) => {
             clearFilters();
             setSearchText('');
         };
         const getColumnSearchProps = (dataIndex) => ({
             filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
                 <div
                     style={{
                         padding: 8,
                     }}
                     onKeyDown={(e) => e.stopPropagation()}
                 >
                     <Input
                         ref={searchInput}
                         placeholder={`Search ${dataIndex}`}
                         value={selectedKeys[0]}
                         onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                         onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                         style={{
                             marginBottom: 8,
                             display: 'block',
                         }}
                     />
                     <Space>
                         <Button
                             type="primary"
                             onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                             icon={<SearchOutlined />}
                             size="small"
                             style={{
                                 width: 90,
                             }}
                         >
                             Search
                         </Button>
                         <Button
                             onClick={() => clearFilters && handleReset(clearFilters)}
                             size="small"
                             style={{
                                 width: 90,
                             }}
                         >
                             Reset
                         </Button>
                     </Space>
                 </div>
             ),
             filterIcon: (filtered) => (
                 <SearchOutlined
                     style={{
                         color: filtered ? '#4C1319' : undefined,
                     }}
                 />
             ),
             onFilter: (value, record) =>
                 record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
             onFilterDropdownOpenChange: (visible) => {
                 if (visible) {
                     setTimeout(() => searchInput.current?.select(), 100);
                 }
             },
             render: (text) =>
                 searchedColumn === dataIndex ? (
                     <Highlighter
                         highlightStyle={{
                             backgroundColor: '#ffc069',
                             padding: 0,
                         }}
                         searchWords={[searchText]}
                         autoEscape
                         textToHighlight={text ? text.toString() : ''}
                     />
                 ) : (
                     text
                 ),
         });
         // table filter

      useEffect(()=>{
        function getOrderTakeDown(){
          axios.get(`${api}/orderTakeDown.php`)
          .then(res=> setData(res.data.data))
        }
        getOrderTakeDown()
      },[])


      if(!isMounted.current){
        return <div>Loading...</div>
      }



    const columns = [
      {
        title: "Image",
        dataIndex: "image",
        key: "image",
        width: "22%",
        render : (value, row) =>{
          return(
            <img src={value} alt="product-image" className="product-image" />
          )
        }
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        ...getColumnSearchProps('name')
      },
    
      {
        title: "Cost",
        key: "cost",
        dataIndex: "cost",
        render : (value, row) =>{
          return(
          <p>${value}</p>
          )
        }
      },
      {
        title: "Sales",
        key: "sales",
        dataIndex: "sales",
        render : (value, row) =>{
          return(
          <p>${value}</p>
          )
        }
      },
      {
        title: "Tax",
        key: "tax",
        dataIndex: "tax",
        render : (value, row) =>{
          return(
          <p>${value}</p>
          )
        }
      },
      {
        title: "Revenue",
        key: "revenue",
        dataIndex: "revenue",
        render : (value, row) =>{
          let totalAmount = row && parseFloat(row.tax) + parseFloat(row.sales) ;
          return(
          <p>${totalAmount}</p>
          )
        }
      },
    ];

    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title="Products Revenue"
              >
                <div className="table-responsive">
                  {data && data?.products?.length > 0 &&
                  <Table
                    columns={columns}
                    dataSource={data.products}
                    pagination={false}
                    className="ant-border-space"
                  />
                  }
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default OrderTakeDown;
  