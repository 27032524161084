import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
} from "antd";
import { Typography } from "antd";
import { ExportOutlined} from "@ant-design/icons";
import axios from "axios";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";
import CatSection from "../components/chart/catchart";
import useIsMounted from '../hook/mounted';
import { api } from "../api";






function Home() {
  const { Title} = Typography;
  const isMounted = useIsMounted();

  const [data, setData] = useState()
  
  useEffect(() => {
    gettingAllData()
    function gettingAllData() {
      axios.get(`${api}/stats.php`)
        .then(res => {
          setData(...[res.data.data])
        })
    }
  },[])
  
  
  if(!isMounted.current){
    return <h2 className="text-center my-4">Getting Stats...</h2>
  }



    return (
      <>
        <div className="layout-content">
          <Row gutter={[24, 0]}>
            {
              data && data.stats && data.stats.map((val,key)=>(
                <Col xs={24} sm={24} md={8} className="mb-24">
                  <Card bordered={false} className={"criclebox"}>
                    <div className="number">
                      <Row align="middle" gutter={[24, 0]}>
                        <Col xs={18}>
                              <Title level={3}>
                                  {data.currency + ' ' + val.value} <small className="">({val.label})</small>
                              </Title>
                          </Col>
                          <Col xs={6}>
                              <div className="icon-box"><ExportOutlined /></div>
                          </Col>
                          {val.details &&
                                <ul className="expense-list">
                                  
                                      <li><span>Shipping :</span> <span>${val.details.shipping}</span></li>
                                      <li><span>Tax :</span> <span>${val.details.tax}</span></li>
                                      <li><span>Costing :</span> <span>${val.details.productCosting}</span></li>
                                </ul>
                              }
                      </Row>
                    </div>
                  </Card>
                </Col>
              ))
            }
            
          </Row>
          <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
              <Card bordered={false} className="criclebox h-full home-card">
                <h3>Top 6 Sellings Product's</h3>
                {data && data.prodStats && data.prodStats.data?.length > 0 ?
                    <Echart  data={data.prodStats} />                    
                    : 'No Stats To Preview'
                  }
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
              <Card bordered={false} className="criclebox home-card">
                <h3>Most Selling Products</h3>
               
                {data && data.products && data.products.length > 0 ?
                   <ul className="product-list">
                    {
                      data.products.map((val, key)=>(
                        
                        <li>
                          <div>
                            <img src={val.image} alt="product" />
                            <h6>{val.name}</h6>
                          </div>
                          <h4>{data.currency + ' ' + Number(val.sales).toFixed(2)}</h4>
                        </li>
                      ))
                    }
                  </ul>                   
                    : 'No Stats To Preview'
                  }
              </Card>
            </Col>
          </Row>
          <CatSection />
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24} className="mb-24">
              <Card bordered={false} className="criclebox h-full">
                <div className="linechart">
                  <div>
                    <Title level={5}>Cash Flow </Title>
                  </div>
                </div>
                  <LineChart  />
              </Card>
            </Col>
          </Row>

          {/* Leads */}
    
        </div>
      </>
    );
}

export default Home;



