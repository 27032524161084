import { useEffect, useState } from "react";

import {
  Row,
  Col,
  Dropdown,
  Avatar,
  Menu,
  message,
} from "antd";

import { LoginInfo } from "../../hook/lognInfo";
import axios from "axios";
import { useNavigate } from "react-router-dom";






function Header() {
  const Navigate = useNavigate();
  let {fullname} = LoginInfo('all')



  const logOut = () => {
    localStorage.removeItem('anoop_cred')
    message.success('Logout !')
    setTimeout(() => {
      Navigate('/sign-in')
    }, 1000);

  }


  const profileMenu = (
    <Menu className="style-menu">
      <Menu.Item onClick={logOut}>Log Out</Menu.Item>
    </Menu>

  );


  return (
    <>
      <Row gutter={[24, 0]} align="middle">
        <Col span={12} md={6}>
          <h2 className="mb-0 text-white text-capitalize">Welcome Back</h2>
        </Col>
        <Col span={12} md={18} className="header-control">
          <Dropdown overlay={profileMenu} trigger={["click"]} >
                 <Avatar className="profile text-uppercase">{fullname && fullname?.slice(0,1)}</Avatar>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default Header;
