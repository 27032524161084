import { Navigate } from "react-router-dom";
import { LoginInfo } from "./lognInfo";

const AdminProtection = ({ children }) => {
  const { role } = LoginInfo('all')
  if (role !== 'admin') {
    return <Navigate to="/orders" replace />;
  }
  return children;
};

const ProtectedRoute = ({ children }) => {
  if (!localStorage.getItem('anoop_cred')) {
    return <Navigate to="/sign-in" replace />;
  }

  return children;
};


export { AdminProtection, ProtectedRoute };