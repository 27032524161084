import { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Card,
    Table,
    Menu,
    message,
  } from "antd";

  import StyleDropdown from "../components/forms/dropdown";
  import { useDispatch, useSelector, shallowEqual } from "react-redux";
  import { all_data } from "../store/action/order";
  import { Link } from "react-router-dom";
  import { SearchOutlined } from '@ant-design/icons';
import { Input, Space , Button} from 'antd';
import Highlighter from 'react-highlight-words'
import { api } from "../api";


  function Orders() {
    const dispatch = useDispatch()
    const orders = useSelector(state => state.orders.data, shallowEqual)

        // table filter
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);
    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };
    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#4C1319' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) =>
            searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{
                        backgroundColor: '#ffc069',
                        padding: 0,
                    }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });
    
      useEffect(()=>{

        dispatch(all_data())

      },[dispatch])


    const columns = [
      {
        title: "Billing Detail",
        dataIndex: "billing",
        key: "billing",
        width: "22%",
        render : (value) =>( 
          <div>
            <h5 className="text-capitalize">{value && value.first_name + ' ' + value.last_name},</h5>
            <h5 className="text-capitalize">{value && value.phone}</h5>
          </div>
        )
      },
      {
        title: "Total Amount",
        dataIndex: "total",
        key: "total",
        render : (value) =>(<span>{ '$' + value}</span>),
      },
    
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render : (value) =>(<span className={"status " + value}>{value}</span>),
        filters: [
          {
            text: 'Completed',
            value: 'completed',
          },
          {
            text: 'Pending',
            value: 'pending',
          },
          {
            text: 'Refunded',
            value: 'refunded',
          },
        ]
      },
      {
        title: "Order Date",
        key: "date_created",
        dataIndex: "date_created",
        render : (value) =>(new Date(value).toDateString()),
        ...getColumnSearchProps('date_created')
      },
      {
        title: "Order Id",
        key: "id",
        dataIndex: "id",
        render : (value) =>(new Date(value).toDateString()),
        ...getColumnSearchProps('id')
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        render : (value, {id}) =>{
          const menu = (
            <Menu>
              <Menu.Item key={1} ><Link to={'/orders/'+ value}>View Order</Link></Menu.Item>
            </Menu>
          );
          return(
            <StyleDropdown menu={menu} />
          )
         
        }
      },
    ];
    const exportToExcel =  () =>{
      let user = JSON.parse(localStorage.getItem('anoop_cred'))
      if(!user.username){
          localStorage.removeItem('anoop_cred')
      }
      window.open(`${api}/orders.php?getExcel=${user.username}`, '_blank');
      message.success('Generating Order Report')
    }
    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24"
                title={
                  <div className="order-header">
                      <h2>Order List</h2>
                      <Button onClick={exportToExcel}>Exprt To Excel</Button>
                  </div>
                }
              >
                <div className="table-responsive">
                  {orders && orders.length > 0 &&
                  <Table
                    columns={columns}
                    dataSource={orders && orders.length > 0 ? orders : [] }
                    pagination={false}
                    className="ant-border-space"
                  />
                  }
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
  
  export default Orders;
  