import { useEffect, useState } from "react";
import {
    Row,
    Col,
    Card,
    Form,
    Button,
    Menu,
    message
  } from "antd";
  import { Link } from "react-router-dom";
  import { PlusCircleOutlined } from '@ant-design/icons';

  // component
  import StyleDropdown from "../components/forms/dropdown";
  import { useDispatch, useSelector, shallowEqual } from "react-redux";
  import { all_data} from "../store/action/employee";
  import StyleModal from "../components/modal";
import StyleTable from "../components/table";
import FormButton from '../components/forms/button';
import InputPassword from '../components/forms/inputPassword';
import InputConfirmPassword from '../components/forms/inputConfirmPassword';
import axios from "axios";
import { api } from "../api";
  // table code start



 
  
  function User() {
    const dispatch = useDispatch()
    const [catModal, setCatModal] = useState(false);
    const [userId, setUserId] = useState(0);
    const employee = useSelector(state => state.employee.data, shallowEqual)

    
    //brandId
    const activateUser = (id) =>{
      axios.get(`${api}/users/index.php?activate_user=${id}`)
      .then(res =>{
        if(res.data.status){
          message.success(res.data.message)
          dispatch(all_data())
        }
      })
      .catch(e => message.error('failed to activate user'))
    }

    const deactivateUser = (id) =>{
      axios.get(`${api}/users/index.php?deactivate_user=${id}`)
      .then(res =>{
        if(res.data.status){
          message.success(res.data.message)
          dispatch(all_data())
        }
      })
      .catch(e => message.error('failed to deactivate user'))
    }

    useEffect(()=>{
      dispatch(all_data())
    },[dispatch])



    


    const columns = [
      {
        title: "Username",
        dataIndex: "username",
        key: "username",
        width: "200",
      },
      {
        title: "user_role",
        dataIndex: "role",
        width: "150",
      },
    
      {
        title: "Created time",
        dataIndex: "time",
        key: "time",
        width: "150",
        render : (value) =>(<>{new Date(value).toDateString()}</>)
      },
      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        width: "200",
        render : (value) =>{
          let status = value && Number(value) === 1 ? 'active' : 'deactive'
          return(
          <p className={status+'-btn status'}>{status}</p>
          )
          
        }
      },
      {
        title: "Actions",
        key: "id",
        dataIndex: "id",
        width: "100",
        render : (value, {id, status, username}) =>{
          console.log(status)
          const menu = (
            <Menu>
              {
                username && username !== 'admin' &&
                <>
                {
                  Number(status) === 1 ? 
                  <Menu.Item key={1}  onClick={()=>deactivateUser(id)}>Deactivate</Menu.Item>
                  :
                  <Menu.Item key={2}  onClick={()=>activateUser(id)}>Activate</Menu.Item>
                }
                </>
              }
              <Menu.Item key={2}  onClick={()=> showResetPasswordModal(id)}>Update Password</Menu.Item>
              <Menu.Item key={2}  onClick={()=> deleteUser(id)}>Delete User</Menu.Item>
             
            </Menu>
          );
          return(
            <StyleDropdown menu={menu} />
          )
         
        }
      },
    ];


    const showResetPasswordModal = (id) =>{
       setCatModal(true);
       setUserId(id);
    }

    const deleteUser = (id)=>{
      axios.get(`${api}/users/userUpdate.php?user_id=${id}`)
      .then(res =>{
        if(res.data.status){
          message.success(res.data.message)
          dispatch(all_data())
        }
        else{
          message.error(res.data.message)
        }
      })
    }
    const resetPassword = (values) =>{
      values = {...values, id : userId}
      axios.post(`${api}/users/userUpdate.php`, JSON.stringify(values))
      .then(res =>{
        console.log(values)
        if(res.data.status){
          message.success(res.data.message)
          setCatModal(false);
        }
        else{
          message.error(res.data.message)
        }
      })

    }

    let propsData = {
      columns : columns,
      data : employee,
      pageSize :  8,
      height : 500,

    }

    return (
      <>
        <div className="tabled">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24}>
              <Card
                bordered={false}
                className="criclebox tablespace mb-24 employee-card"
                title="Users"
                extra={
                  <div className="button-box">
                    <Link to="/addUser"><Button icon={<PlusCircleOutlined />} className="icon-button">Add User</Button></Link> 
                  </div>
                }
              >
  
                  {employee && employee.length > 0 &&
                  <StyleTable {...propsData} />
  }
              </Card>
            </Col>
          </Row>
        </div>
              {/* assign writer modal */}
              {catModal && <StyleModal view={catModal} footer={null} >
                    <div className='create-form-inner modal-form'>
                        <h2 className="heading">Reset Password</h2>
                        <Form
                            name="resetPassword"
                            className='brand-form'
                            initialValues={{}}
                            onFinish={resetPassword}
                        >
                            <Row gutter={10}>
                                <Col span={24}>
                                  <InputPassword name="password" label="Enter new password" />
                                </Col>
                                <Col span={24}>
                                  <InputConfirmPassword name="password_confirm" label="Confirm new password" />
                                </Col>
                                <Col span={12} >
                                    <FormButton value="submit" btntype="submit" />
                                </Col>
                                <Col span={12} >
                                    <FormButton value="Close" btntype="button" onClick={() => setCatModal(false)} />
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </StyleModal>
                }
      </>
    );
  }
  
  export default User;
  