import { Modal } from 'antd';
import './style.css';
const StyleModal = (props) => {
  return (
    <>
      <Modal visible={props.view} {...props} closable={false} className="style-modal">
        {props.children}
      </Modal>
    </>
  );
};
export default StyleModal;