import  { Card,
Col,
Row,
} from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import {api} from '../../api'
import { Chart } from "react-google-charts";





function CatSection() {
  const [data , setData] = useState([])
  
  useEffect(()=>{
    function getflow(){
      axios.get(`${api}/categorySales.php`)
      .then(res => {
        if(res.data.status){
          setData(res.data.data)
        }
      })
    }
    getflow()
  },[])
  console.log(data)


  const options = {
    legend: { position: 'top', alignment: 'center' },        
    pieSliceText: 'label',
  };

  if(data && data.length <= 0){
    return <div>Generating Cash Flow</div>
  }

  return (
    <>
        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
              <Card bordered={false} className="criclebox h-full home-card">
                <h3>Top 6 Selling Category</h3>
                {data && data.stats && data.stats.length > 0 ?
                <>
                <div className="pie-chart">
                <Chart
                  chartType="PieChart"
                  data={data.stats}
                  options={options}
                  width={"600px"}
                  height={"400px"}
                />
                </div>
                   

                </>
       
                    : 'No Stats To Preview'
                  }
              </Card>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
              <Card bordered={false} className="criclebox home-card">
                <h3>Top Selling Category</h3>
               
                {data && data.categories && data.categories.length > 0 ?
                   <ul className="product-list">
                    {
                      data.categories.map((val, key)=>(
                        
                        <li>
                          <div>
                            <h6>{val.name}</h6>
                          </div>
                          <h4>{data.currency + ' ' + Number(val.sales).toFixed(2)}</h4>
                        </li>
                      ))
                    }
                  </ul>                   
                    : 'No Stats To Preview'
                  }
              </Card>
            </Col>
    </Row>
      
    </>
  );
}

export default CatSection;
