import { combineReducers } from "redux";
import orderReducer from "./order";
import employeeReducer from "./employee";

const rootReducers = combineReducers({
    orders : orderReducer,
    employee : employeeReducer,

})

export default rootReducers ;