
import { Form, Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';

export default function InputPassword({ name, icon, message }) {
    return (
        <Form.Item
            className='custom-input mb-0'
            name={name}
            rules={[
                {
                    required: true,
                    message: message,
                    min:4
                },
            ]}
        >
            <Input.Password
                prefix={icon}
                type="password"
                placeholder="Password"
                iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            />
        </Form.Item>
    )
}

