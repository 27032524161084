import { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { api } from "../api";
import UseIsMounted from "../hook/mounted";
import { AiFillPrinter } from "react-icons/ai";
import { Tooltip } from 'antd';
import ReactToPrint from "react-to-print";
import { LoginInfo } from "../hook/lognInfo";
import {
  Row,
  Col,
  Card,
  Table,
  Menu,
} from "antd";
// table code start


function ViewOrder() {
  let componentRef = useRef();
  const isMounted = UseIsMounted()
  let { role } = LoginInfo('all')
  let { id } = useParams();

  const [data, setData] = useState([])


  useEffect(() => {
    function getOrder() {
      axios.get(`${api}/orders.php?order_id=${id}`)
        .then((res) => {
          // console.log(res)
          setData(res.data.data)
        })
    }
    getOrder()
  }, [])
  if (!isMounted.current) {
    return <div className="text-center my-4">Loading...</div>
  }
  console.log(data)
  console.log(data.line_items)


  // const dataSource = [
  //   {
  //     key: '1',
  //     name: 'Mike',
  //     age: 32,
  //     address: '10 Downing Street',
  //   },
  //   {
  //     key: '2',
  //     name: 'John',
  //     age: 42,
  //     address: '10 Downing Street',
  //   },
  // ];

  const columns = [
    {
      title: "No :",
      dataIndex: "",
      key: "",
      width: '5%',
      render: (text, record, index) => index + 1,
    },
    {
      width: '45%',
      title: 'Product Name :',
      dataIndex: 'name',
    },
    {
      width: '10%',
      title: 'QTY :',
      dataIndex: 'quantity',
    },
    {
      width: '10%',
      title: 'Price :',
      dataIndex: 'price',
    },
    {
      width: '10%',
      title: 'Amount:',
      dataIndex: 'subtotal',
    },
  ];


  return (
    <>
      <section className="print-sec">
        <div className="container">
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={24} className="view-col">
              <div className="print-sec">

                <ReactToPrint
                  trigger={() => <Tooltip placement="left" title="Print"><button className="print-btn"><AiFillPrinter /></button></Tooltip>}
                  content={() => componentRef}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="view-order-section" ref={(el) => (componentRef = el)}>
        <div className="container">
        <h2 className="text-center sub-heading">ORDER ID # {data?.id}</h2>
          <Row gutter={[24, 0]}>
            <Col xs="24" xl={12} className="view-col">
          
              <div className="billing-sec">
                <h3>Billing To:</h3>
                {
                  data && data.billing ?
                    <>
                      <div className="billing-detail">
                        <ul>
                          <li>First Name : <span>{data.billing.first_name}</span></li>
                          {role === 'admin' &&
                            <li>Email : <span>{data.billing.email}</span></li>
                          }
                          <li>Country : <span>{data.billing.country}</span></li>
                          <li>Postcode : <span>{data.billing.postcode}</span></li>
                        </ul>
                        <ul>
                          <li>Last Name :<span>{data.billing.last_name}</span></li>
                          {role === 'admin' &&
                            <li>Phone : <span>{data.billing.phone}</span></li>
                          }
                          <li>City : <span>{data.billing.city}</span></li>
                          <li>State : <span>{data.billing.state}</span></li>
                        </ul>
                      </div>
                      <div className="billing-detail2">
                        <ul>
                          {role === 'admin' &&
                            <li>Address 1 : <span>{data.billing.address_1}</span></li>
                          }
                          {role === 'admin' &&
                            <li>Address 2 : <span>{data.billing.address_2}</span></li>
                          }
                        </ul>
                      </div>
                    </>
                    : <p>pleas wait.......</p>
                }
              </div>
            </Col>
            <Col xs="24" xl={12} className="view-col">
              <div className="billing-sec">
                <h3>Shipping To:</h3>
                {
                  data && data.shipping ?
                    <>
                      <div className="billing-detail">
                        <ul>
                          <li>First Name : <span>{data.shipping.first_name}</span></li>
                          {role === 'admin' &&
                            <li>Email : <span>{data.shipping.email}</span></li>
                          }
                          <li>Country : <span>{data.shipping.country}</span></li>
                          <li>Postcode : <span>{data.shipping.postcode}</span></li>
                        </ul>
                        <ul>
                          <li>Last Name :<span>{data.shipping.last_name}</span></li>
                          {role === 'admin' &&
                            <li>Phone : <span>{data.shipping.phone}</span></li>
                          }
                          <li>City : <span>{data.shipping.city}</span></li>
                          <li>State : <span>{data.shipping.state}</span></li>
                        </ul>
                      </div>
                      <div className="billing-detail2">
                        <ul>
                          {role === 'admin' &&
                            <li>Address 1 : <span>{data.shipping.address_1}</span></li>
                          }
                          {role === 'admin' &&
                            <li>Address 2 : <span>{data.shipping.address_2}</span></li>
                          }
                        </ul>
                      </div>
                    </>
                    : <p>pleas wait.......</p>
                }
              </div>
            </Col>
          </Row>
          <div className="tabled-view">
            <Row gutter={[24, 0]}>
              <Col xs="24" xl={24}>
                <Card
                  bordered={false}
                  className="criclebox tablespace mb-24"
                  title="Product's Summary"
                >
                  <div className="table-responsive">
                    {data && data.line_items && data.line_items.length > 0 ? (
                      <Table
                        columns={columns}
                        dataSource={data.line_items}
                        pagination={false}
                        className="ant-border-space"
                      />
                    ) : (
                      <p>No line items available.</p>
                    )}
                  </div>
                </Card>
              </Col>
            </Row>
          </div>
          <Row gutter={[0, 24]} className="summary-column">
            <Col xs="24" xl={12} className="view-col">
              <div className="billing-sec">
                <h3 className="summary-heading">Order Summary :</h3>
                {
                  data && data ?
                    <>
                      <div className="summary-detail">
                        <ul>
                          <li>Sub Amount : <span>{data.total - data.total_tax - data.shipping_total}</span></li>
                          <li>Tax :<span>{data.total_tax}</span></li>
                          <li>Shipping : <span>{data.shipping_total}</span></li>
                          <li>Total Amount : <span>{data.total}</span></li>
                        </ul>
                      </div>
                    </>
                    : <p>pleas wait.......</p>
                }
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}

export default ViewOrder;
