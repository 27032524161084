import React from 'react';
import User from '../components/auth/user';
import { Row, Col, Tabs } from 'antd';
const Adduser = () => {
  return(
    <div className='signup-page'>
      <Row align='center'>
        <Col span={23} md={15}>
          <Tabs defaultActiveKey="1">
            <Tabs.TabPane tab="Add User" key="1">
              <User />
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
  </div>


  )


};
export default Adduser;