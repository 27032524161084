export const LoginInfo = (param) => {
  let info = localStorage.getItem("anoop_cred");
  info = JSON.parse(info);
  if (param === "all") {
    return info;
  }
  if (info) {
    return info[param];
  } else {
    return "";
  }
};
