import {
    Row,
    Col,
    Form,
    Card,
    message
} from "antd";

import { useState } from "react";
import InputIcon from "../forms/inputIcon";
import FormButton from "../forms/button";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import {api} from '../../api';
import InputPassword from '../forms/inputPassword';
import InputConfirmPassword from '../forms/inputConfirmPassword';





export default function User({employee_id}) {
    const Navigate = useNavigate()

    const [form] = Form.useForm();
    const [blockSubmit, setBlockSubmit] = useState(false)
    const [profileId, setProfileId] = useState(0)

    const newUSer = (value) =>{
        setBlockSubmit(true)
        let url= '';
        if(employee_id){
            url=`${api}/users/signup.php`;
            value = {...value ,  user_id : employee_id, profile_id:profileId}
        }
        else{
            url=`${api}/users/signup.php`;
        }
        axios.post(url, JSON.stringify(value))
        .then(res =>{
            if(res.data.status){
                message.success(res.data.message)
                form.resetFields();
                setTimeout(() => {
                    Navigate('/users')
                }, 1500);
                setBlockSubmit(false)
            }
            else{
                setBlockSubmit(false)
                message.error(res.data.message)
            }
        })
        .catch(e => message.error('Something Went Wrong'))
    }


    useEffect(()=>{
    },[])

    return (
        <Card bordered={false} className="criclebox cardbody h-full style-card create-order-form">
            <div className='create-form-inner'>
                <h2 className="heading">{employee_id ? 'Update User' : 'Add User'}</h2>
                <Form
                    form={form}
                    name="createorder"
                    className='brand-form'
                    initialValues={{ }}
                    onFinish={newUSer}
                >
                    <Row align="center">

                        <Col span={24}  className="input-div">
                            <InputIcon name='fullname' message='Full Name' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                        <InputIcon name='username' message='Username' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputPassword name='password' message='Password' type="string" />
                        </Col>
                        <Col span={24} className="input-div">
                            <InputConfirmPassword name='confirm_password' message='confirm Password' type="string" />
                        </Col>
                        <Col span={8} >
                            <FormButton value="Submit" btntype="submit" disabled={blockSubmit} />
                        </Col>
                    </Row>

                </Form>
            </div>
        </Card>
    )
}