import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import axios from "axios";
import {api} from '../../api'




function LineChart() {
  const [data , setData] = useState([])
  
  useEffect(()=>{
    function getflow(){
      axios.get(`${api}/cashflow.php`)
      .then(res => {
        if(res.data.status){
          setData(res.data.data)
        }
      })
    }
    getflow()
  },[])
  console.log(data)



  if(data && data.length <= 0){
    return <div>Generating Cash Flow</div>
  }

  return (
    <>
      <ReactApexChart
        className="full-width"
        options={{
          chart: {
            width: "100%",
            height: 350,
            type: "area",
            toolbar: {
              show: false,
            },
          },
      
          legend: {
            show: false,
          },
      
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
      
          yaxis: {
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: ["#8c8c8c"],
              },
              formatter: function(val, index) {
                return Math.round(val);
              }
            },
            min: 0,
          },
      
          xaxis: {
            categories: data.label,    
            labels: {
              style: {
                fontSize: "14px",
                fontWeight: 600,
                colors: "#8c8c8c"
              },
            },
          },
      
          tooltip: {
            y: {
              formatter: function (val) {
                return val;
              },
            },
          },
          colors: ["#247BA0", "#FF1654", "orange"],
        }}
        series={data.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
