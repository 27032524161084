import {Form, Input } from 'antd';

export default function InputIcon(props) {
    return (
        <Form.Item
            name={props.name}
            className='custom-input'
            rules={[
                {
                    required: true,
                    type : props.type,
                    message: props.message,
                },
            ]}
        >
            <Input prefix={props.icon} placeholder={props.message} {...props} />
        </Form.Item>
    )
}