
import { Form, Button } from "antd";

export default function FormButton(props) {
    return (
        <Form.Item>
            <Button type="primary" htmlType={props.btntype} className="style-btn style-btn-theme w-100" {...props}>
                {props.value}
            </Button>
        </Form.Item>
    )

}
